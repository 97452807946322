import React from 'react'

export default function Testimonial({ data }) {
    console.log("Testimonial data", data)
    return (
        <section className="flex flex-col lg:flex-row justify-center items-center w-full ">

            <div className="relative hidden lg:block  bg-primary-blue w-15% xl:w-15% h-50v ls:h-80v md:h-50v lg:h-50v  ">
            </div>

            <div className="relative w-80% lg:w-25% xl:w-25% z-40 overflow:visible ">
                <img className="block overflow:visible mx-auto object-cover ls:h-90v ls:w-80v  h-60v w-full md:h-80v lg:h-60v rounded-3xl" src={data.image} alt="" />
            </div>


            <div className=" relative  bg-primary-blue w-full -mt-8  lg:w-65% ls:h-80v ls:w-90v  h-70v lg:mt-0 md:h-50v lg:h-50v ">
                <div className=" px-4 mx-auto">
                    <div className="flex flex-wrap items-center justify-center  -mx-4">

                        {/* <div className="my-8 w-full flex justify-center h-px ">
                        <div className="h-full w-1/3 bg-orange-primary" />
                    </div> */}
                        <div className="w-full  px-4 mt-8 lg:mt-0  flex flex-col justify-center items-center ">
                            <div className="max-w-xl mt-8 ">
                                <span className="inline-block mb-5">
                                    <svg width={36} height={36} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M26 29.45C26 19.3858 33.4577 8.86747 45.2297 6.16443C45.6793 6.0612 46.1364 6.29467 46.3301 6.71327L47.5161 9.27572C47.7602 9.80299 47.5031 10.4238 46.9877 10.692C43.3044 12.608 40.1644 17.1966 39.3647 20.8059C39.2493 21.3268 39.6241 21.8042 40.1413 21.9351C44.6581 23.0784 48 27.1681 48 32.042C48 38.406 42.832 42 37.602 42C31.572 42 26 37.39 26 29.45ZM0 29.45C0 19.3858 7.45772 8.86747 19.2297 6.16443C19.6793 6.0612 20.1364 6.29467 20.3301 6.71327L21.5161 9.27572C21.7602 9.80299 21.5031 10.4238 20.9877 10.692C17.3044 12.608 14.1644 17.1966 13.3647 20.8059C13.2493 21.3268 13.6241 21.8042 14.1413 21.9351C18.6581 23.0784 22 27.1681 22 32.042C22 38.406 16.832 42 11.602 42C5.572 42 0 37.39 0 29.45Z" fill="#f05a22" />
                                    </svg>
                                </span>
                                {/* <h2 className="mb-10 text-4xl font-semibold font-heading">{data.description}</h2> */}
                                <p className="mb-5 lg:font-body2 xl:font-body1"><span className="text-gray-100">{data.description} </span></p>

                                <p className="mb-3 pr-4 font-heading3"> <span className="text-gray-100">{data.name} </span></p>
                                {/* <p className="text-gray-500">{data.role}</p> */}

                                <div className="my-8 w-full flex justify-center h-px ">
                                    <div className="h-full w-1/2 bg-orange-primary" />
                                </div>
                                {/* <div className=" ">
                                <img className="block mx-auto  h-44 lg:h-44 object-cover rounded-3xl" src={data.logo} alt="" />
                            </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}