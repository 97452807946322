import React, { useRef } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  Scrollbar,
  Autoplay,
} from "swiper";
import Pattern1 from "../images/svg-icons/rainbow-vortex.svg";
import { Link } from "gatsby";

// Import Swiper styles
import "swiper/css";

const news = [
  {
    headline:
      "Mobile app buyout firm KalaGato commits $100 million for app acquisition",
    logo_url:
      "https://res.cloudinary.com/dyarjdpvz/image/upload/v1643992202/logo_yourstory_47b4403471.svg",
  },
  {
    headline:
      "ALT Mobility launches debt aggregation platform for commercial electric vehicle leasing, plans on investing USD 100mn in next 12 months",
    logo_url:
      "https://res.cloudinary.com/dyarjdpvz/image/upload/v1668401489/business_standard_logo_e0f514e621.png",
  },
  {
    headline: "How Xook is Modernizing the Cafeteria Landscape",
    logo_url:
      "https://res.cloudinary.com/dyarjdpvz/image/upload/v1668830672/Xtalks_logo_wtl2dn.jpg",
  },
];

export default (data) => {
  const swiperRef = useRef(null);
  SwiperCore.use([
    Navigation,
    Pagination,
    Mousewheel,
    Keyboard,
    Scrollbar,
    Autoplay,
  ]);
  const test = (Swiper) => {
    return (
      <button
        onMouseEnter={() => Swiper.autoplay.stop()}
        onMouseLeave={() => Swiper.autoplay.start()}
      >
        {" "}
        Hover me{" "}
      </button>
    );
  };
  return (
    <div
      onMouseEnter={() => swiperRef.current.swiper.autoplay.stop()}
      onMouseLeave={() => swiperRef.current.swiper.autoplay.start()}
    >
      <Swiper
        ref={swiperRef}
        spaceBetween={50}
        slidesPerView={1}
        autoplay={{ delay: 7000 }}
        //   onSlideChange={() => console.log('slide change')}
        //   onSwiper={(swiper) => { console.log(swiper) }}
        parallax={true}
        pagination={{ clickable: true }}
        mousewheel={true}
        keyboard={true}
        onInit={(Swiper) => {
          test(Swiper);
        }}
        className="swiper-container-news"
      >
        {news.map((post) => (
          <SwiperSlide>
            <Link to={"/press"}>
              <div className=" bg-sky-50 hover:bg-sky-100 ">
                <div className="ls:h-16 md:hidden w-full bg-sky-50"> </div>

                <div className="w-100v h-60v   flex flex-col justify-center items-center  ">
                  <div
                    className="hidden absolute h-44 w-44 top-75% -left-10   overflow-hidden rounded-full lg:block  bg-gradient-to-br from-orange-100 shadow-2xl to-orange-300 "
                    aria-hidden="true"
                  ></div>
                  <div
                    className="hidden absolute h-44 w-44 top-10% -right-8   overflow-hidden rounded-full lg:block bg-gradient-to-br from-blue-200 to-sky-300   shadow-2xl opacity-50"
                    aria-hidden="true"
                  ></div>

                  <div className="js-scroll js-animate-vote">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-12 w-12 text-orange-primary"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z"
                      />
                    </svg>
                  </div>

                  <h2 className="mt-8 lg:mt-16 px-8 max-w-6xl ">
                    <span className="block font-heading4">{post.headline}</span>
                    <br />
                    <br />
                    {/* <span className="block text-1xl  tracking-wide text-orange-700 sm:text-xl font-normal ">{post.source}</span> */}
                    {/* <span className="block text-1xl  tracking-tight text-gray-600 sm:text-1xl font-medium ">{post.published_date}</span> */}
                    <img
                      class=" object-contain h-20 w-20 mx-auto   "
                      src={post.logo_url}
                      alt=""
                    />
                  </h2>
                  <div
                    className="  h-16 md:h-4 w-44 top-10% -right-8   overflow-hidden rounded-full lg:block bg-gradient-to-br from-blue-200 to-sky-300   shadow-2xl opacity-50"
                    aria-hidden="true"
                  ></div>
                </div>
              </div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
