import React from 'react'
import {
    CloudUploadIcon,
    LockClosedIcon,
    RefreshIcon,

} from '@heroicons/react/outline'
import { ChevronRightIcon, ExternalLinkIcon } from '@heroicons/react/solid'
const features = [
    {
        name: 'Push to Deploy',
        description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi vitae lobortis.',
        icon: CloudUploadIcon,
    },
    {
        name: 'SSL Certificates',
        description: 'Qui aut temporibus nesciunt vitae dicta repellat sit dolores pariatur. Temporibus qui illum aut.',
        icon: LockClosedIcon,
    },
    {
        name: 'Simple Queues',
        description: 'Rerum quas incidunt deleniti quaerat suscipit mollitia. Amet repellendus ut odit dolores asdasd qui.',
        icon: RefreshIcon,
    }
]

export default function LandingFeatures() {
    return (
        <div className="relative bg-blue-100 py-16 sm:py-24 lg:py-32">
            <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
                <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                    Who are we ?
                </p>
                <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
                    We are a sector-agnostic early-stage VC firm that operates on a founder-first philosophy to catalyze game changers, kickstart growth, and enrich the entrepreneurial journey to contribute towards creating an enduring ecosystem.
                </p>

                {/* <div className="mt-12">
                    <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                        {features.map((feature) => (
                            <div key={feature.name} className="pt-6">
                                <div className="flow-root bg-gradient-to-r from-sky-800 to-cyan-700 rounded-lg px-6 pb-8 shadow-2xl">
                                    <div className="-mt-6">
                                        <div>
                                            <span className="inline-flex items-center justify-center p-3 bg-gradient-to-r from-teal-500 to-cyan-600 rounded-md shadow-lg">
                                                <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                                            </span>
                                        </div>
                                        <h3 className="mt-8 text-lg font-medium text-gray-100 tracking-tight">{feature.name}</h3>
                                        <p className="mt-5 text-base text-gray-200">{feature.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div> */}
            </div>
        </div>
    )
}
