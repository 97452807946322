
import React, { useEffect } from 'react';
import { ArrowCircleRightIcon } from '@heroicons/react/outline'


export default function LandingDemo({ }) {

    useEffect(() => {
        const callback = function (entries) {
            entries.forEach((entry) => {
                console.log(entry);

                if (entry.isIntersecting) {
                    console.log(entry.target.className);

                    if (entry.target.className.includes('js-animate-fade-in-down')) {
                        entry.target.classList.add("animate-fade-in-down");
                    }
                    if (entry.target.className.includes('js-animate-fade-in-right')) {
                        entry.target.classList.add("animate-fade-in-right");
                    }
                    if (entry.target.className.includes('js-animate-fade-in-left')) {
                        entry.target.classList.add("animate-fade-in-left");
                    }
                    if (entry.target.className.includes('js-animate-fade-in-up')) {
                        entry.target.classList.add("animate-fade-in-up");
                    }
                    if (entry.target.className.includes('js-animate-fade-in-up-text')) {
                        entry.target.classList.add("animate-fade-in-up-text");
                    }

                } else {
                    // entry.target.classList.remove("animate-fade-in-left");
                }
            });
        };

        const observer = new IntersectionObserver(callback);

        const targets = document.querySelectorAll(".js-scroll");
        targets.forEach(function (target) {
            observer.observe(target);
        });
    }, [])

    return <>
        <div className="relative h-auto  bg-gradient-to-tr from-blue1 to-blue2   overflow-scroll">

            {/* <div className="relative h-auto  bg-gradient-to-tr from-orange-light1 to-orange-light2   overflow-scroll"> */}

            <div className="absolute h-750 w-750 bg-gradient-to-br from-transparent shadow-2xl to-blue-200 top-15 right-20 opacity-60 rounded-full filter blur-3xl " ></div>
            {/* <div className="absolute h-3/5 w-96 bg-gradient-to-br from-transparent shadow-2xl to-yellow-400 top-0 left-0 opacity-60 rounded-bl-full filter blur-3xl" ></div> */}

            {/* <div className="absolute h-96 w-3/6 bg-gradient-to-br from-transparent shadow-2xl to-gray-300 bottom-0 left-0 opacity-60 rounded-tr-full filter blur-3xl" ></div> */}
            {/* <div className="absolute h-96 w-3/6 bg-gradient-to-br from-transparent shadow-2xl to-gray-300 bottom-0 right-0 opacity-40 rounded-full filter blur-3xl" ></div>  */}




            <div className="    min-h-screen mx-auto  ">

                <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16 ml-32 mt-72 ">
                    <h1 className="text-4xl tracking-tight font-extrabold  text-gray-200 sm:text-5xl md:text-6xl lg:text-5xl xl:text-8xl">
                        <span className="block xl:inline">Relationship</span>{' '}
                        <br />
                        <span className="block  text-gray-200 xl:inline"> <div className=" block text-orange2 js-scroll js-animate-fade-in-up-text "> Beyond </div>the Cap Table</span>
                    </h1>
                    <button
                        type="button"
                        className=" absolute mt-10   inline-flex items-center px-8 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-orange2 bg-yellow-300 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        PITCH TO US
                        <ArrowCircleRightIcon className="ml-3 -mr-1 h-5 w-5" aria-hidden="true" />
                    </button>
                </div>


                <div className="absolute h-96 w-96 bg-gradient-to-br from-transparent shadow-2xl to-blue-200 top-40 right-96 opacity-60 rounded-full filter blur-1xl hover-zoom js-scroll js-animate-fade-in-left  flex justify-center items-center pr-32 animate-ping" >

                    <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl text-gray-200 opacity-100">People</h2>

                </div>
                <div className="absolute h-350 w-350 bg-gradient-to-br from-transparent shadow-2xl to-blue-200 top-20 right-40 opacity-60 rounded-full filter blur-1xl hover-zoom js-scroll js-animate-fade-in-down  flex justify-center items-center pl-24" >
                    <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl text-gray-200 ">Portfolio</h2>

                </div>
                <div className="absolute h-96 w-96 bg-gradient-to-br from-transparent shadow-2xl to-blue-100 top-80 right-60 opacity-60 rounded-full filter blur-1xl hover-zoom js-scroll js-animate-fade-in-up flex justify-center items-center pl-20 pt-24 " >
                    <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl text-gray-200 ">About Us</h2>

                </div>


            </div>

        </div>
    </>
}