import React from "react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  Scrollbar,
  Autoplay,
} from "swiper";
import Pattern1 from "../images/svg-icons/rainbow-vortex.svg";

import Testimonial from "./testimonial";

const portfolios = [
  // { name: 'Kiran Pai', role: 'CEO & Founder', description: 'Mobile technology, the remote controls we use to navigate our daily lives, are transaction engines that never leave our pocket. Never ever have physicians had this simple of a way to interact with pharma — never ever have pharma cos been equipped to redesign their physician interaction model.', image: 'https://res.cloudinary.com/dyarjdpvz/image/upload/v1637581168/small_Single_dose_founder_1489f8ec60.jpg', logo: 'https://res.cloudinary.com/dyarjdpvz/image/upload/v1634790100/thumbnail_TSB_Media_WPF_2_2cc251e5fb.jpg' },
  // { name: 'Vikas Malpani', role: 'CEO & Founder', description: 'We help a creator build a highly engaged community with live free-flowing conversations.  Leher unlocks value by providing the ability to monetize high-quality community and conversations.', image: 'https://res.cloudinary.com/dyarjdpvz/image/upload/v1645079806/vikas_hued2y.jpg', logo: 'https://res.cloudinary.com/dyarjdpvz/image/upload/v1637123553/thumbnail_Leher_Logo_Circle_ba8a56cb28.png' },
  // { name: 'Aditya Uttaravali', role: 'CEO & Founder', description: 'We, at Fleek, are building a world-class subscription management and marketplace platform which will enable users to manage their current subscriptions as well as enable them to purchase new ones.', image: 'https://res.cloudinary.com/dyarjdpvz/image/upload/v1645779148/aditya_yfmgne.jpg', logo: 'https://res.cloudinary.com/dyarjdpvz/image/upload/c_scale,w_260/v1637163897/fleek_black_BG_Square_9c6d168d77.png' },
  // { name: 'Sunny Sen', role: 'CEO & Founder', description: 'ConsCent is building an ecosystem to connect media companies with millions of paying users by making premium content accessible and affordable for everyone while enabling a more powerful revenue stream for content providers.', image: 'https://res.cloudinary.com/dyarjdpvz/image/upload/v1637581169/small_conscent_founders_bc853c6fda.jpg', logo: 'https://res.cloudinary.com/dyarjdpvz/image/upload/v1634790098/TSB_Media_WPF_5_f84d6a33b7.jpg' }

  {
    name: "Aalesh Avlani",
    role: "CEO & Founder",
    description:
      "At Credit Wise Capital, we leverage a qualitative decision mechanism to disburse two-wheeler consumer loans through a completely branchless model.",
    image:
      "https://res.cloudinary.com/dyarjdpvz/image/upload/v1704789490/Aalesh_CWC_Photo_Room_transformed_8e7bdcde88.png",
    logo: "https://res.cloudinary.com/dyarjdpvz/image/upload/v1702650299/thumbnail_creditwise_e008301983_b12ddb0d5c.png",
  },
  {
    name: "Dev Arora",
    role: "CEO & Co-Founder",
    description:
      "Our vision at Alt Mobility is to power widespread electrification with accessible and flexible solutions, driving the future of sustainable transportation.",

    image:
      "https://res.cloudinary.com/dyarjdpvz/image/upload/v1704788643/Dev_Alt_Mobility_Photo_Room_transformed_c0274f7ef1.png",

    logo: "https://res.cloudinary.com/dyarjdpvz/image/upload/v1704788642/Alt_Mobility_logo_a6fd0ff317.png",
  },

  {
    name: "Aman Kumar",
    role: "CEO & Founder",
    description:
      "At Kalagato, we are building a cutting-edge consumer intelligence platform built on data-driven app buyouts.",
    image:
      "https://res.cloudinary.com/dyarjdpvz/image/upload/v1704789490/Aman_Kalagato_Photo_Room_transformed_2a89681def.png",
    logo: "https://res.cloudinary.com/dyarjdpvz/image/upload/v1702650296/kalagato_d9644ea83b_4b216c7e2c.png",
  },
  {
    name: "Surya Prakash Yadav",
    role: "CEO & Co-Founder",
    description:
      "At UnORG, we are building the largest and fastest-growing B2B marketplace for unorganized FMCG sector in India.",

    image:
      "https://res.cloudinary.com/dyarjdpvz/image/upload/v1704788643/Surya_Un_ORG_Photo_Room_transformed_1a705e985e.png",

    logo: "https://res.cloudinary.com/dyarjdpvz/image/upload/v1704788767/Un_ORG_logo_3c0ef0474b.jpg",
  },
];

export default () => {
  SwiperCore.use([
    Navigation,
    Pagination,
    Mousewheel,
    Keyboard,
    Scrollbar,
    Autoplay,
  ]);

  return (
    <div className=" swiper-container">
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        cssMode={true}
        //navigation={true}
        pagination={{ clickable: true }}
        //scrollbar={{ draggable: true }}
        mousewheel={true}
        keyboard={true}
        autoplay={{ delay: 6000 }}
        //onSlideChange={() => console.log('slide change')}
        //onSwiper={(swiper) => console.log(swiper)}
        //parallax={true}
        className=""
      >
        {portfolios.map((item) => (
          <SwiperSlide>
            <Testimonial data={item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
