import React, { useEffect } from 'react';

import Layout from "../components/layout"
import Hero from "../components/hero"
import SignupNewsletter from "../components/sign-up-newsletter"
import LandingStats from "../components/landing_stats"
import LandingAboutUs from "../components/landing_about_us"
import LandingFeatures from "../components/landing_features"
import { scrollAnimation } from "../lib/utils"
import LandingPortfolio from "../components/landing_portfolio"

import LandingNews from "../components/landing_news"

import LandingDemo from './landing-demo';
import PortfolioCards from "../components/portfolio-cards"
import Portfolio_Section from '../components/portfolio-section';
import Testimonial from "../components/testimonial"
import { graphql } from 'gatsby'

const IndexPage = ({ location, data }) => {

  useEffect(() => {
    scrollAnimation()


  }, [])

  return (

    // <Layout>
    //   <Hero />
    //   <LandingStats />
    //   <PortfolioCards />
    //   <NewsLetter />
    // </Layout>

    <Layout location={location} >

      <Hero />
      {/* <LandingFeatures /> */}

      <LandingAboutUs />
      <LandingPortfolio data={data} />
      <LandingNews data={data} />
      {/* <SignupNewsletter /> */}
    </Layout>

    // <LandingDemo />
  )
}

export default IndexPage

export const fetchAllLandingQuery = graphql`
  {
    allNews {
        newsFeeds(limit: 4,sort: "published_date:DESC") {
            id
            headline
            source
            url
            published_date
            logo_url
          }
        }
  }
`;