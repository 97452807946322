import React from 'react'
export default function LandingAboutUs() {
    return (
        <section className="py-12  overflow-hidden mt-44 sm:mt-10 md:py-20 lg:py-24">
            <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="relative">

                    <blockquote className="mt-20 sm:mt-10">
                        <div className="max-w-6xl  mx-auto font-heading2 js-scroll js-animate-fade-in-down ">
                            <p className="text-center leading-loose">
                                We are a sector-agnostic early-stage VC firm that operates on a <span className="text-orange-500"> founder-first </span> philosophy to catalyze <span className="text-orange-500"> game changers</span>, kickstart growth, and enrich the entrepreneurial journey to contribute towards creating an <span className="text-orange-500">enduring </span>  ecosystem.

                            </p>
                        </div>

                    </blockquote>
                </div>
            </div>
        </section >
    )
}
